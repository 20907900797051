<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Comprobantes de Compras</h4>
            <div class="small text-muted">Crear y administrar todos los comprobantes de compras</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-purchase" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="10">            
            <b-row>
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>  
              <b-col sm="3">
                <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterPurchase()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                class = 'pull-right'
                :data = "arr.export"
                worksheet = "Listado de Compras"
                name = "list-purchase.xls">
                Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>              
      </b-card>

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_purchase">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Comprobante">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>                
              </b-col>              
              <b-col sm="12">
                <b-table class="mb-0 table-purchase-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(voucher)="data">
                      <div v-if="data.item.type_voucher && data.item.point_sale">
                        <b-badge variant="dark" v-if="data.item.points_sales">
                          {{data.item.points_sales.point_sale}} - {{data.item.points_sales.name}}
                        </b-badge> <br>
                        {{data.item.type_voucher.name}}
                        {{data.item.point_sale.toString().padStart(4,'0') + '-' + data.item.number.toString().padStart(8,'0')}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>
                    
                    <template v-slot:cell(suppliers_id)="data">  
                      <div v-if="data.item.supplier">            
                        <b-avatar :src="data.item.supplier.image"
                                  v-if="data.item.supplier.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"
                                  variant="dark">
                        </b-avatar>                                                     
                        &nbsp;<b>{{data.item.supplier.name}}</b>   
                        
                        <b-icon icon="circle-fill" v-if="!data.item.supplier.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Proveedor INACTIVO" />
                      </div>
                    </template>

                    <template v-slot:cell(amount_total)="data">  
                      <div v-if="data.item.currency">
                        <div style="color:green;" v-if="data.item.type_voucher.type_balance == '-'">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount_total)}}</b>
                        </div>
                        <div style="color:red;" v-else>
                          <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount_total)}})</b>
                        </div>
                      </div>
                    </template>

                    <template v-slot:cell(amount_pending)="data">  
                      <div v-if="data.item.currency">
                        <div style="color:red;" v-if="data.item.type_voucher.type_balance == '+' && data.item.amount_total!=data.item.amount_impute">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(getPendingVoucherPurchase(data.item))}}</b>
                        </div>
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">
                      <span v-if="data.item.file">
                        <b-link target="blank" :href="data.item.file" v-b-tooltip.hover.right title="Ver documento adjunto" class="text-center">
                          <b-icon icon="paperclip"></b-icon>
                        </b-link>
                      </span>                                  
                      <span v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations" class="mr-1 text-center">
                        <b-icon icon="chat-square-text"></b-icon>
                      </span>                                                           
                    </template>
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTablePurchaseShow"
                                  @hide="setConfigTablePurchaseHide">
                        <b-dropdown-header>Relaciones</b-dropdown-header>
                        <b-dropdown-item @click="openSetRemits(data.item)" v-if="data.item.type_voucher.type_balance == '+'">
                          <b-icon icon="truck"></b-icon> Remitos
                        </b-dropdown-item>                        
                        <b-dropdown-item @click="openSetFile(data.item)">
                          <b-icon icon="paperclip"></b-icon> Documento Físico
                        </b-dropdown-item>
                        
                        <b-dropdown-header>Acciones</b-dropdown-header>
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>                             
                        <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deletePurchase">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- COMPROBANTE SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-purchase-title" v-if="itemSelected">
                        <div class="crud-purchase-title">
                          <span class="crud-purchase-code" v-if="itemSelected.type_voucher && itemSelected.point_sale">
                            {{itemSelected.type_voucher.name}} {{itemSelected.point_sale.toString().padStart(4,'0') + '-' + itemSelected.number.toString().padStart(8,'0')}}
                          </span><br>
                          <span v-if="itemSelected.supplier">
                            {{this.itemSelected.supplier.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->  
                  <b-row>
                    <b-col>
                      <b-link v-if="itemSelected"
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                                           
                    </b-col>
                  </b-row>        
                  <b-row v-if="tableSubIva.items.length">
                    <b-col md="12">
                      <b-table  class="mb-0 table-purchase-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubIva.items"
                                :fields="tableSubIva.fields"                            
                                v-if="tableSubIva.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 
                        
                        <template v-slot:cell(iva_condition)="data">
                          {{data.item.iva_condition.name}}
                        </template>

                        <template v-slot:cell(amount_net)="data">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_net)}}
                        </template>

                        <template v-slot:cell(amount_iva)="data">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_iva)}}
                        </template>

                        <template v-slot:cell(amount_total)="data">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_total)}}
                        </template>                      

                      </b-table>                      
                      <b-alert v-else variant="warning" show>Sin discriminación de IVA</b-alert>
                    </b-col>                    
                  </b-row>

                  <b-row v-if="tableSubRemits.items.length">
                    <b-col md="12">
                      <hr>
                    </b-col>
                  </b-row>
                  <b-row v-if="tableSubRemits.items.length">
                    <b-col md="12">
                      <b-table  class="mb-0 table-purchase-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubRemits.items"
                                :fields="tableSubRemits.fields"                            
                                v-if="tableSubRemits.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 

                        <template v-slot:cell(date)="data">
                          {{moment(data.item.data.date).format('DD/MM/YYYY')}}                          
                        </template>

                        <template v-slot:cell(remits)="data">
                          {{data.item.data.type_voucher.name}} {{data.item.data.point_sale.toString().padStart(4,'0') + '-' + data.item.data.number.toString().padStart(8,'0')}}
                        </template>                      

                      </b-table>                      
                      <b-alert v-else variant="warning" show>Sin discriminación de Percepciones</b-alert>
                    </b-col>                    
                  </b-row>              

                  <b-row v-if="tableSubPercepcion.items.length">
                    <b-col md="12">
                      <hr>
                    </b-col>
                  </b-row>
                  <b-row v-if="tableSubPercepcion.items.length">
                    <b-col md="12">
                      <b-table  class="mb-0 table-purchase-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubPercepcion.items"
                                :fields="tableSubPercepcion.fields"                            
                                v-if="tableSubPercepcion.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 

                        <template v-slot:cell(percepcion)="data">
                          {{data.item.perception.name}}
                        </template>

                        <template v-slot:cell(amount_total)="data">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_total)}}
                        </template>                      

                      </b-table>                      
                      <b-alert v-else variant="warning" show>Sin discriminación de Percepciones</b-alert>
                    </b-col>                    
                  </b-row>                  
                  
                  <b-row v-if="(tableSubPercepcion.items.length || tableSubPercepcion.items.length) && tableImputation.items.length">
                    <b-col md="12">
                      <hr>
                    </b-col>
                  </b-row>                                          
                  <b-row v-if="tableImputation.items.length">
                    <b-col md="12">                      
                      <b-table  class="mt-2 table-sales-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableImputation.items"
                                :fields="tableImputation.fields"                            
                                v-if="tableImputation.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                        
                        <template v-slot:cell(date)="row"> 
                          {{moment(row.item.date).format('DD/MM/YYYY')}}
                        </template>              

                        <template v-slot:cell(reference)="row"> 
                          {{row.item.reference}}                          
                        </template>

                        <template v-slot:cell(amount_total)="row"> 
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(row.item.amount_total)}}           
                        </template>                        

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron imputaciones</b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_purchase">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterPurchase()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-sales-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="purchaseValidDetailGeneral">          
            <b-row>      
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
              <b-col md="4">
                <b-form-group label="Fecha Vencimiento">
                  <b-form-datepicker size="sm" v-model="crud.form.date_expiration" placeholder="Fecha Vencimiento" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
              <b-col md="4">
                <b-form-group label="Fecha Imputación">
                  <b-form-datepicker size="sm" v-model="crud.form.date_imputation" placeholder="Fecha Imputación" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>              
            </b-row>              
            <b-row> 
              <b-col md="4">
                <FindObject render="search"
                            type="suppliers" 
                            @select-object="loadSuppliers($event)" 
                            :valueID="crud.form.suppliers_id"
                            :where="conditionSuppliersActive"
                            :disabled="crud.form.id>0" />                 
              </b-col>                             

              <b-col md="3">
                <b-form-group label="Tipo">    
                  <b-form-select v-model="crud.form.types_vouchers_id" 
                                :options="arr.select.typeVouchers" 
                                @change="setLetter($event)"
                                size="sm"
                                :disabled="crud.form.id>0">
                  </b-form-select>                          
                </b-form-group>                            
              </b-col>       
              <b-col md="2">
                <b-form-group label="Punto de Venta">    
                  <b-form-input v-model="crud.form.point_sale" 
                                type="number" 
                                size="sm" 
                                min="0" 
                                max="9999" 
                                step="1">
                  </b-form-input>            
                </b-form-group>                            
              </b-col>  
              <b-col md="3">
                <b-form-group label="Número">    
                  <b-form-input v-model="crud.form.number" 
                                type="number" 
                                size="sm" 
                                min="0" 
                                max="99999999" 
                                step="1">
                  </b-form-input>                              
                </b-form-group>                            
              </b-col>                                                    
            </b-row>
            <b-row>
              <b-col>
                <hr>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col md="9">
                <b-row>
                  <b-col md="4"> 
                    <FindObject tag="Punto de Venta (Imputación)"
                                render="search"
                                type="erpPointsSales" 
                                @select-object="loadPointsSales($event)" 
                                :valueID="crud.form.points_sales_id"
                                :key="'finder_point_sale_'+componentKey"
                                :disabled="crud.form.id>0"  />                 
                  </b-col>                
                  <b-col md="4">
                    <FindObject type="currency" 
                                @select-object="loadCurrency($event)" 
                                :key="'finder_currency_'+componentKey"
                                :valueID="crud.form.currency_id"
                                :disabled="crud.form.id>0"/>                 
                  </b-col>
                </b-row>
              </b-col>               
              <b-col md="3">                
                <b-form-group label="Importe Final" description="Ingrese el importe final de la factura">    
                  <b-input-group size="md" prepend="$">
                    <b-form-input v-model="crud.form.amount_total" 
                                  type="number"                                   
                                  min="0"  
                                  step="0.01">
                    </b-form-input>            
                  </b-input-group>
                </b-form-group>                            
              </b-col>              
            </b-row>
          </tab-content>

          <tab-content title="Impuestos" icon="fa fa-percent" :before-change="purchaseValidItemDetail" v-if="crud.form.letter == 'A'" :key="'tab_b_'+componentKey">          
            <b-row>             
              <b-col md="6" class="crud-purchase-impuestos-divisor">
                <b-row>
                  <b-col md="3">                    
                    <b-form-group label="Condición IVA">    
                      <b-form-select v-model="crud.form.ivaDetail.iva_condition_id" 
                                    :options="arr.select.ivaCondition" 
                                    @change="changeIvaCondition($event)"
                                    size="sm">
                      </b-form-select>                    
                    </b-form-group>
                  </b-col>

                  <b-col md="5">                    
                    <b-form-group label="Neto" description="Neto sobre el cual se calcula el IVA">    
                      <b-input-group size="sm" prepend="$">
                        <b-form-input v-model="crud.form.ivaDetail.net"
                                      @change="changeNet()" 
                                      type="number"                                   
                                      min="0"  
                                      step="0.01">
                        </b-form-input>            
                      </b-input-group>                                            
                    </b-form-group>                            
                  </b-col>

                  <b-col md="3">                    
                    <b-form-group label="IVA" description="Importe del IVA" v-if="crud.form.ivaDetail.iva_condition">    
                      <b-input-group size="sm" prepend="$">
                        <b-form-input v-model="crud.form.ivaDetail.iva" 
                                      type="number"                                   
                                      min="0"  
                                      step="0.01"
                                      :disabled="!parseFloat(crud.form.ivaDetail.iva_condition.aliquot)">
                        </b-form-input>            
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>         
                  <b-col md="1" class="p-0">         
                      <b-button size="sm" 
                                variant="success" 
                                class="crud-purchase-item-add" 
                                v-b-tooltip.hover 
                                title="Agregar"
                                @click="addItemIVA()">
                        <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
                      </b-button>           
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>                    
                    <b-table-simple hover small responsive v-if="crud.form.ivaDetail.detail.length">             
                      <b-thead head-variant="dark">                                          
                        <b-tr>                      
                          <b-th width="25%" class="text-center">Alicuota</b-th>
                          <b-th width="20%" class="text-right">Neto</b-th>
                          <b-th width="20%" class="text-right">IVA</b-th>
                          <b-th width="20%" class="text-right">Total</b-th>
                          <b-th width="15%" class="text-center"></b-th>
                        </b-tr>
                      </b-thead>      
                      <b-tbody v-for="(item, index) in crud.form.ivaDetail.detail" :key="'iva_' + index"> 
                        <b-tr>                                       
                          <b-td class="text-center align-middle">
                            {{item.iva_condition.name}}
                          </b-td>
                          <b-td class="text-right align-middle">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.net)}}
                          </b-td>
                          <b-td class="text-right align-middle">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.iva)}}                            
                          </b-td>                          
                          <b-td class="text-right align-middle">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(parseFloat(item.net) + parseFloat(item.iva))}}
                          </b-td>
                          <b-td class="text-center align-middle">
                            <b-button size="sm" 
                                      variant="danger"                                       
                                      @click="removeItemIVA(index)">
                              <b-icon icon="x" aria-hidden="true"></b-icon>
                            </b-button>                               
                          </b-td>
                        </b-tr>
                      </b-tbody>                              
                    </b-table-simple>   
                    <b-alert show variant="warning" v-else>                      
                      Cargar el IVA discriminado                      
                    </b-alert>                            
                  </b-col>
                </b-row>
              </b-col>    

              <b-col md="6">
                <b-row>
                  <b-col md="4">                    
                    <b-form-group label="Percepciones">    
                      <b-form-select v-model="crud.form.percepcionesDetail.percepciones_id" 
                                    :options="arr.select.percepciones" 
                                    @change="changePercepciones($event)"
                                    size="sm">
                      </b-form-select>                    
                    </b-form-group>
                  </b-col>

                  <b-col md="4">                    
                    <b-form-group label="Total" description="Total Percepción">    
                      <b-input-group size="sm" prepend="$">
                        <b-form-input v-model="crud.form.percepcionesDetail.percepcion" 
                                      type="number"                                   
                                      min="0"  
                                      step="0.01">
                        </b-form-input>            
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>         
                  <b-col md="1" class="p-0">         
                      <b-button size="sm" 
                                variant="success" 
                                class="crud-purchase-item-add" 
                                v-b-tooltip.hover 
                                title="Agregar"
                                @click="addItemPercepcion()">
                        <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
                      </b-button>           
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>                    
                    <b-table-simple hover small responsive v-if="crud.form.percepcionesDetail.detail.length">             
                      <b-thead head-variant="dark">                                          
                        <b-tr>                      
                          <b-th width="25%" class="text-left">Percepción</b-th>
                          <b-th width="20%" class="text-right">Total</b-th>
                          <b-th width="15%" class="text-center"></b-th>
                        </b-tr>
                      </b-thead>      
                      <b-tbody v-for="(item, index) in crud.form.percepcionesDetail.detail" :key="'percepciones_' + index"> 
                        <b-tr>                                       
                          <b-td class="text-left align-middle">
                            <span v-if="item.percepciones">{{item.percepciones.name}}</span>
                          </b-td>
                          <b-td class="text-right align-middle">
                            {{ Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.percepcion) }}
                          </b-td>
                          <b-td class="text-center align-middle">
                            <b-button size="sm" 
                                      variant="danger"                                       
                                      @click="removeItemPercepcion(index)">
                              <b-icon icon="x" aria-hidden="true"></b-icon>
                            </b-button>                               
                          </b-td>
                        </b-tr>
                      </b-tbody>                              
                    </b-table-simple>   
                    <b-alert show variant="warning" v-else>
                      Cargar las percepciones recibidas                                             
                    </b-alert>                    
                  </b-col>
                </b-row>
              </b-col> 

              <b-col md="12">
                <b-table-simple hover small responsive bordered>             
                  <b-thead head-variant="dark">                                          
                    <b-tr>                      
                      <b-th width="20%" class="text-right">
                        <div class="mr-1">Subtotal</div>                        
                      </b-th>
                      <b-th width="20%" class="text-right">
                        <div class="mr-1">IVA</div>                        
                      </b-th>
                      <b-th width="20%" class="text-right">
                        <div class="mr-1">Percepciones</div>
                      </b-th>
                      <b-th width="20%" class="text-right">
                        <div class="mr-1">Importe Final</div>
                      </b-th>                      
                      <b-th width="20%" class="text-right">
                        <div class="mr-1" v-if="this.getDiffTotal()==0">APROBADO</div>
                        <div class="mr-1" v-if="this.getDiffTotal()>0">EXEDIDO</div>
                        <div class="mr-1" v-if="this.getDiffTotal()<0">FALTAN</div>
                      </b-th>                                            
                    </b-tr>
                  </b-thead>  
                  <b-tbody> 
                    <b-tr>                                       
                      <b-td class="text-right align-middle">        
                        <div class="mr-1">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getSubtotal() )}}
                        </div>            
                      </b-td>
                      <b-td class="text-right align-middle">   
                        <div class="mr-1">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getIvaTotal() )}}
                        </div>
                      </b-td>
                      <b-td class="text-right align-middle">       
                        <div class="mr-1">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getPercepcionesTotal() )}}
                        </div>
                      </b-td>
                      <b-td class="text-right align-middle">    
                        <div class="mr-1 crud-purchase-totales-add">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getTotal() )}}
                        </div>
                      </b-td>
                      <b-td class="text-right align-middle text-white" :class="{'bg-red': this.getDiffTotal()!=0, 'bg-green':this.getDiffTotal()==0}">
                        <div class="mr-1 crud-purchase-totales-add">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getDiffTotal() )}}
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>                                                                                                 
              </b-col>                                          
            </b-row>
          </tab-content>

          <tab-content title="Facturas" icon="fa fa-link" :before-change="purchaseValidCrudRelation" v-if="crud.form.types_vouchers && (crud.form.types_vouchers.reference=='notacredito' || crud.form.types_vouchers.reference=='credito-nd')" :key="'tab_c_'+componentKey">
            <b-row>              
              <b-col v-if="crud.form.currency">
                <VoucherRelation  :supplier_id="crud.form.suppliers_id" 
                                  :point_sale_id="crud.form.points_sales_id"
                                  :details="crud.form.invoiceRelated"
                                  :amount="crud.form.amount_total"
                                  :currency="crud.form.currency"
                                  v-if="crud.form.currency"
                                  @getInvoice="getInvoiceRelated($event)" />
              </b-col>
            </b-row>
          </tab-content>

          <tab-content title="Conceptos" icon="fa fa-list" :before-change="purchaseValidItemConcepts" v-if="showContabilidad && showConcepts" :key="'tab_d_'+componentKey">          
            <b-row class="mb-1" align-h="center" v-for="(element, index) in crud.form.conceptsDetails" :key="index">      
              <b-col lg="4">
                
                  <b-form-input v-model="element.alias" 
                                type="text"                                   
                                size="sm"
                                :readonly="true">
                  </b-form-input>                            
                
              </b-col>
              <b-col lg="2">
                
                  <b-input-group size="sm" prepend="$">
                    <b-form-input v-model="crud.form.conceptsDetails[index].amount" 
                                  type="number"                                   
                                  min="0"  
                                  step="0.01">
                    </b-form-input>            
                  </b-input-group>
                
              </b-col>
            </b-row>
            <b-row align-h="center" class="mt-3">
              <b-col md="6">
                <b-table-simple hover small responsive bordered>             
                  <b-thead head-variant="dark">                                          
                    <b-tr>                                            
                      <b-th width="20%" class="text-right">
                        <div class="mr-1">Importe Final</div>
                      </b-th>                      
                      <b-th width="20%" class="text-right">
                        <div class="mr-1" v-if="this.getDiffTotalConcepts()==0">APROBADO</div>
                        <div class="mr-1" v-if="this.getDiffTotalConcepts()>0">EXEDIDO</div>
                        <div class="mr-1" v-if="this.getDiffTotalConcepts()<0">FALTAN</div>
                      </b-th>                                            
                    </b-tr>
                  </b-thead>  
                  <b-tbody> 
                    <b-tr>                                                             
                      <b-td class="text-right align-middle">    
                        <div class="mr-1 crud-purchase-totales-add">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getTotalConcepts() )}}
                        </div>
                      </b-td>
                      <b-td class="text-right align-middle text-white" :class="{'bg-red': this.getDiffTotalConcepts()!=0, 'bg-green':this.getDiffTotalConcepts()==0}">
                        <div class="mr-1 crud-purchase-totales-add">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format( this.getDiffTotalConcepts() )}}
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>                                                                                                 
              </b-col>               
            </b-row>
          </tab-content>

          <tab-content title="Asiento Contable" icon="fa fa-balance-scale" :before-change="purchaseValidItemAccounting" v-if="showContabilidad" :key="'tab_e_'+componentKey"> 
            <div v-if="contabilidad.render" :key="'accounting_' + itemAccountingForceUpdate">
              <AccountingEntries  :typeDebe="contabilidad.typeDebe"
                                  :typeHaber="contabilidad.typeHaber"
                                  :defaultDebe="contabilidad.defaultDebe"
                                  :defaultHaber="contabilidad.defaultHaber"
                                  :reference="contabilidad.reference"
                                  :amountTotal="contabilidad.amountTotal"
                                  @getAsiento="getAsiento($event)" />         
            </div>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered" :key="'tab_f_'+componentKey">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">ID</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>       
                    <tr v-if="itemSelected.points_sales">
                      <td class="crud-purchase-title-table-custom align-middle">Punto de Venta Imputación</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{this.itemSelected.points_sales.point_sale}} - {{this.itemSelected.points_sales.name}}                          
                      </td>
                    </tr>                                      
                    <tr v-if="itemSelected.type_voucher && itemSelected.point_sale">
                      <td class="crud-purchase-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-purchase-value-table-custom align-middle">
                        <b>{{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelected.staff">
                      <td class="crud-purchase-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{itemSelected.staff.name}}  
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">Fecha</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">Fecha Vencimiento</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{moment(this.itemSelected.date_expiration).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">Fecha Imputación</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{moment(this.itemSelected.date_imputation).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                                                           
                    <tr v-if="itemSelected.supplier">
                      <td class="crud-purchase-title-table-custom align-middle">Proveedor</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{this.itemSelected.supplier.name}}                        
                      </td>
                    </tr>                                                  
                    <tr v-if="itemSelected.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Neto Gravado</td>
                      <td class="crud-purchase-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_net)}}                        
                      </td>
                    </tr>  
                    <tr v-if="itemSelected.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Neto No Gravado</td>
                      <td class="crud-purchase-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_not_taxed)}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.currency">
                      <td class="crud-purchase-title-table-custom align-middle">IVA</td>
                      <td class="crud-purchase-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_iva)}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Percepciones</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_perceptions)}}
                      </td>
                    </tr>                    
                    <tr v-if="itemSelected.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Total</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(this.itemSelected.amount_total)}}</b>                        
                      </td>
                    </tr>       
                    <tr v-if="itemSelected.amount_impute!=itemSelected.amount_total">
                      <td class="crud-purchase-title-table-custom align-middle">Pendiente de Imputación</td>
                      <td class="crud-purchase-value-table-custom align-middle table-warning">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelected.currency.code}).format(getPendingVoucherPurchase(this.itemSelected))}}</b>                        
                      </td>
                    </tr>                                                        
                    <tr v-if="itemSelected.observations">
                      <td class="crud-purchase-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>                              
                    <tr v-if="itemSelected.file">
                      <td class="crud-purchase-title-table-custom align-middle">Documento</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        <b-link :href="itemSelected.file" target="blank">
                          <b>Ver Archivo</b>
                        </b-link>
                      </td>
                    </tr>                                                  
                  </tbody>
                </table>
              </b-tab>    

              <b-tab title="Impuestos">                
                <b-row>
                  <b-col>
                    <b-table class="mb-0 table-full-detail-purchase"
                            responsive
                            head-variant="dark"
                            :hover="true"
                            :small="true"
                            :fixed="true"
                            :items="tableSubIva.items"
                            :fields="tableSubIva.fields"
                            v-if="tableSubIva.items.length">                       
                        
                      <template v-slot:table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"                    
                          :key="field.key"
                          :style="{ width: field.width }"
                        >
                      </template>           

                      <template v-slot:cell(iva_condition)="data">
                        {{data.item.iva_condition.name}}
                      </template>

                      <template v-slot:cell(amount_net)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_net)}}
                      </template>

                      <template v-slot:cell(amount_iva)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_iva)}}
                      </template>

                      <template v-slot:cell(amount_total)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_total)}}
                      </template>                      

                    </b-table>                      
                    <b-alert v-else variant="warning" show>Sin discriminación de IVA</b-alert>
                  </b-col>
                  <b-col>
                    <b-table class="mb-0 table-full-detail-purchase"
                            responsive
                            head-variant="dark"
                            :hover="true"
                            :small="true"
                            :fixed="true"
                            :items="tableSubPercepcion.items"
                            :fields="tableSubPercepcion.fields"
                            v-if="tableSubPercepcion.items.length">                       
                        
                      <template v-slot:table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"                    
                          :key="field.key"
                          :style="{ width: field.width }"
                        >
                      </template>           


                      <template v-slot:cell(percepcion)="data">
                        {{data.item.perception.name}}
                      </template>

                      <template v-slot:cell(amount_total)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelected.currency.code}).format(data.item.amount_total)}}
                      </template>                      

                    </b-table>                      
                    <b-alert v-else variant="warning" show>Sin discriminación de Percepciones</b-alert>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Imputaciones" v-if="tableImputation.items.length">                
                <b-table class="mb-0 table-full-detail-purchase"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableImputation.items"
                        :fields="tableImputation.fields">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(date)="data"> 
                    {{moment(data.item.date).format('DD/MM/YYYY')}}
                  </template>              

                  <template v-slot:cell(reference)="data"> 
                    {{data.item.reference}}                          
                  </template>

                  <template v-slot:cell(amount_total)="data"> 
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.amount_total)}}           
                  </template>         

                </b-table>
              </b-tab>

              <b-tab title="Remitos" v-if="tableRemits.items.length">
                <b-table class="mb-0 table-full-detail-purchase"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableRemits.items"
                        :fields="tableRemits.fields"
                        v-if="tableRemits.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>                                                                                     

                  <template v-slot:cell(date)="data">
                    {{moment(data.item.remit.date).format('DD/MM/YYYY')}}
                  </template>  

                  <template v-slot:cell(remits)="data">
                    <div v-if="data.item.remit.type_voucher">
                      {{data.item.remit.type_voucher.name + ' '  + data.item.remit.point_sale.toString().padStart(4,'0') + '-' + data.item.remit.number.toString().padStart(8,'0')}}
                    </div>                                       
                  </template>

                  <template v-slot:cell(code)="data">
                    <div v-html="getProductCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(description)="data">
                    <div v-html="getProductName(data.item)"></div>
                  </template>

                  <template v-slot:cell(quantity)="data">
                    {{data.item.quantity}}
                    <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>                                       
                  </template>

                  <template v-slot:cell(price)="data">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(data.item.price_cost)}}                                             
                  </template>                                    

                  <template v-slot:cell(total)="data">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(parseFloat(data.item.price_cost) * parseFloat(data.item.quantity))}}                                             
                  </template>                                    

                  <template v-slot:cell(action)="data">
                    <b-link @click="unlinkRemitsDetail(data.item)">
                      Desvincular
                    </b-link>
                  </template>                                    
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>

              <b-tab title="Asiento" v-if="moduleAccountingActive">
                <b-table class="mb-0 table-full-detail-purchase"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableAccounting.items"
                        :fields="tableAccounting.fields"
                        v-if="tableAccounting.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>                                                                                     

                  <template v-slot:cell(code)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_code}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_code}}</span>
                    </div>
                  </template>  

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_name}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_name}}</span>
                    </div>                                                          
                  </template>

                  <template v-slot:cell(debit)="data">
                    <b v-if="data.item.type == 'Debe'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </b>
                  </template>

                  <template v-slot:cell(credit)="data">
                    <b v-if="data.item.type == 'Haber'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </b>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

      <!-- FILE -->
      <b-modal v-model="modal.formFile.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formFile.title}}
        </div>

        <b-row>      
          <b-col md="12">
            <div class="crud-purchase-title" v-if="itemSelected">
              <div class="crud-purchase-title">
                <span v-if="itemSelected.type_voucher && itemSelected.point_sale" class="crud-purchase-code">
                  {{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}
                </span><br>                
                <span v-if="itemSelected.supplier">
                  {{this.itemSelected.supplier.name}}   
                </span>
              </div>              
            </div>
          </b-col>
          <b-col>
            <br>
          </b-col>             
          <b-col md="12">
            <b-form-group label="Documento">                
              <b-form-file v-if="!crud.formFile.fileLink" 
                           v-model="crud.formFile.file"                             
                           size="sm"
                           :state="Boolean(crud.formFile.file)"
                           placeholder="Elija un archivo o sueltelo aquí..."
                           drop-placeholder="Suelta el archivo aquí...">
              </b-form-file>      
              <div v-else>
                <b-link :href="crud.formFile.fileLink" target="_blank">
                  <b>Ver Archivo</b>
                </b-link>           
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.formFile.fileLink=''" v-b-tooltip.hover title="Cargar otro archivo">
                  <i class="fa fa-refresh"></i>
                </b-button>                                         
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formFile.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveFile()">Guardar</b-button>
        </div>
      </b-modal>

      <!-- REMITS -->
      <b-modal v-model="modal.formRemits.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close     
              size="xl" 
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formRemits.title}}
        </div>

        <b-row>      
          <b-col md="12">
            <div class="crud-purchase-title" v-if="itemSelected">
              <div class="crud-purchase-title">
                <span v-if="itemSelected.type_voucher && itemSelected.point_sale" class="crud-purchase-code">
                  {{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}
                </span><br>                
                <span v-if="itemSelected.supplier">
                  {{this.itemSelected.supplier.name}}   
                </span>
              </div>              
            </div>
          </b-col>
          <b-col>
            <br>
          </b-col>          
          <b-col md="12">
            <b-table-simple hover small caption-top responsive v-if="itemSelectedRemit && itemSelectedRemit.length" class="crud-remit-table-items">
              <b-thead head-variant="dark">                    
                <b-tr>
                  <b-th width="10%" class="text-left">Fecha</b-th>
                  <b-th width="20%" class="text-left">Remito</b-th>
                  <b-th width="10%" class="text-left">Código</b-th>
                  <b-th width="30%" class="text-left">Descripción</b-th>
                  <b-th width="10%" class="text-right">Cantidad</b-th>
                  <b-th width="10%" class="text-right">Precio</b-th>
                  <b-th width="10%" class="text-center">Vincular</b-th>                  
                </b-tr>
              </b-thead>      
              <b-tbody v-for="item in itemSelectedRemit" :key="item.id">                                         
                <b-tr v-if="item.remit ">
                  <b-td class="text-left align-middle">
                    {{moment(item.remit.date).format('DD/MM/YYYY')}}
                  </b-td>
                  <b-td class="text-left align-middle">
                    <div v-if="item.remit.type_voucher">
                      {{item.remit.type_voucher.name + ' '  + item.remit.point_sale.toString().padStart(4,'0') + '-' + item.remit.number.toString().padStart(8,'0')}}
                    </div>                    
                  </b-td>
                  <b-td class="text-left align-middle">
                    <div v-html="getProductCode(item)"></div>
                  </b-td>
                  <b-td class="text-left align-middle">                        
                    <div v-html="getProductName(item)"></div>
                  </b-td>
                  <b-td class="text-right align-middle">
                    {{item.quantity}}
                    <span v-if="item.unit_measurement">{{item.unit_measurement.reference}}</span>
                  </b-td>                           
                  <b-td class="text-right align-middle">                        
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.price_cost)}}                                             
                  </b-td>                               
                  <b-td class="text-center align-middler">
                    <b-form-checkbox v-model="item.check"                                       
                                      @click="getSummaryRemits()"
                                      switch 
                                      size="sm" 
                                      class="pull-center">
                    </b-form-checkbox> 
                  </b-td>                 
                </b-tr>                    
              </b-tbody>       
              <b-tfoot>
                <b-tr>
                  <b-td colspan="7" variant="secondary" class="text-right">
                    Total: <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(this.getSummaryRemits())}}</b>
                  </b-td>
                </b-tr>
              </b-tfoot>              
            </b-table-simple>   
            <b-alert show variant="warning" v-else>
              No hay items de remitos para asociar.
            </b-alert>                            

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formRemits.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveRemits()">Guardar</b-button>
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Funciones from '@/handler/funciones'
  import FindObject from '@/components/inc/find/findObject'  
  import AccountingEntries from '@/components/inc/accounting/entries'  
  import VoucherRelation from './inc/detailInvoice'
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'

  export default {
    components: {
      FindObject,
      FormWizard,
      TabContent,      
      AccountingEntries,
      VoucherRelation,
    },
    data: () => {
      return {      
        access: {
            module_id: Modules.COMPRAS,
            profile_id: Profiles.PERSONAL,
            view_reference: 'purchase',
            elements: {
              deletePurchase: true
            }
        },
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },     
        tableSubIva : {
          items: [],
          fields: []
        },
        tableSubPercepcion : {
          items: [],
          fields: []
        },   
        tableSubRemits: {
          items: [],
          fields: []
        },      
        tableAccounting : {
          items: [],
          fields: []
        },
        tableImputation : {
          items: [],
          fields: []
        },
        tableRemits : {
          items: [],
          fields: []
        },        
        crud: {
          form: {
            id: 0,
            date: '',
            date_expiration: '',              
            date_imputation: '',              
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            point_sale: 0,
            number: 0,
            observations: '',            
            staff_id: 0,              
            suppliers_id: 0,
            suppliers: null,      
            currency_id: 0,
            currency: null,
            points_sales_id: 0,
            points_sales: null,              
            detail: [],
            ivaDetail: {
              net: 0,
              iva: 0,
              iva_condition_id: 0,
              iva_condition: null,
              detail: [],
            },
            percepcionesDetail: {
              percepcion: 0,
              percepciones_id: 0,
              percepciones: null,
              detail: [],
            },
            conceptsDetails: [],
            invoiceRelated: [],
            accountingEntry: null,
            amount_total: 0,
          },
          formFile: {
            id: 0,
            fileLink: '',
            file: null,
          },            
        },          
        modal: {
          form: {
            active: false,
            title: '',
          },
          formFile: {
            active: false,
            title: '',
          },
          formRemits: {
            active: false,
            title: '',
          },
          detail: {
            active: false,
            title: ''
          }
        },          
        arr: {
          purchase: [],   
          typeVouchers: [],
          ivaCondition: [],            
          percepciones: [],            
          export: [],
          select: {
            typeVouchers: [],            
            ivaCondition: [],          
            percepciones: [],              
          },         
          filters : {
            suppliers: [],
          }
        },           
        filters: {
          date_start: '',
          date_end: '',
          suppliers: null,
        },     
        itemSelected: null,   
        itemSelectedRemit: null,
        itemAccountingForceUpdate: 0,          
        contabilidad: {
          render: false,
          typeDebe:"",                                 
          typeHaber:"",
          defaultDebe:[],
          defaultHaber:[],
          reference:'',
          amountTotal:0
        },
        componentKey: 0,
      }      
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.deletePurchase = Helper.hasAccessElement(this.access, 'delete_purchase') 
      /* Fin configuracion */
    },     
    computed: {
      // CONDITIONS SELECT
      conditionSuppliersActive(){
        return [{field: 'active', condition: true}];
      },

      // MODULES
      moduleAccountingActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {                             
            status = true                       
          }
        })        
        return status
      },
      
      // CURRENCY
      getCurrency() {
        if(this.crud.form.currency) {          
          return this.crud.form.currency.code
        } else {
          return 'ARS'
        }
      },

      // VOUCHER
      getRefVoucher() {
        if(this.crud.form.types_vouchers && this.crud.form.point_sale && this.crud.form.number) {
          return this.crud.form.types_vouchers.name + ' '  + this.crud.form.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.number.toString().padStart(8,'0')  
        } else {
          return ''
        }      
      },

      // CONFIGURACION
      showContabilidad() {
        if(Modules.CONTABILIDAD) {
          return true
        } else {
          return false
        }              
      },
      showConcepts() {
        var show = false
        if(this.crud.form.suppliers) {
          if(this.crud.form.suppliers.accounting_account) {            
            if(this.crud.form.suppliers.accounting_account.concepts) {
              show = true              
            }
          }
        }
        return show        
      },    
    },
    mounted() {   
      this.loadFieldTable()
      this.filterLoad()   
      this.filterPurchase()              
      this.loadStyleConfig()
    },
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_purchase = document.getElementById('view_list_purchase')
        var view_footer_purchase = document.getElementById('view_footer_purchase')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_purchase.classList.remove('pr-0')
          view_footer_purchase.classList.remove('pr-0')
        } else {          
          view_list_purchase.classList.add('pr-0')
          view_footer_purchase.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE COMPROBANTES
        this.table.fields.push({key: 'voucher', label: 'Comprobante', sortable: true, class:"align-middle text-left", width:"150px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"90px"})
        this.table.fields.push({key: 'suppliers_id', label: 'Proveedor', class:"align-middle", width:"150px"})
        this.table.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"80px"})
        this.table.fields.push({key: 'amount_pending', label: 'Pendiente', class:"align-middle text-right", width:"80px"})                      
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"25px"})
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSubIva.fields.push({key: 'iva_condition', label: 'IVA', class:"align-middle", width:"25%"})                
        this.tableSubIva.fields.push({key: 'amount_net', label: 'Neto', class:"align-middle text-right", width:"25%"})
        this.tableSubIva.fields.push({key: 'amount_iva', label: 'IVA', class:"align-middle text-right", width:"25%"})
        this.tableSubIva.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"25%"})

        // TABLE PERCEPCIONES
        this.tableSubPercepcion.fields.push({key: 'percepcion', label: 'Percepción', class:"align-middle", width:"75%"})                
        this.tableSubPercepcion.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"25%"})

        // TABLE REMITOS
        this.tableSubRemits.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-left", width:"25%"})
        this.tableSubRemits.fields.push({key: 'remits', label: 'Remito', class:"align-middle", width:"75%"})
      
        // TABLE ASIENTO
        this.tableAccounting.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"20%"})                
        this.tableAccounting.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"40%"})                
        this.tableAccounting.fields.push({key: 'debit', label: 'Debe', class:"align-middle", width:"20%"})                
        this.tableAccounting.fields.push({key: 'credit', label: 'Haber', class:"align-middle", width:"20%"})

        // TABLE IMPUTACIONES
        this.tableImputation.fields.push({key: 'date', label: 'Fecha', class:"align-middle", width:"20%"})                
        this.tableImputation.fields.push({key: 'reference', label: 'Referencia', class:"align-middle", width:"50%"})           
        this.tableImputation.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"30%"})      
        
        // TABLE REMITOS
        this.tableRemits.fields.push({key: 'date', label: 'Fecha', class:"align-middle", width:"10%"})                
        this.tableRemits.fields.push({key: 'remits', label: 'Remito', class:"align-middle", width:"15%"})           
        this.tableRemits.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"10%"})              
        this.tableRemits.fields.push({key: 'description', label: 'Descripción', class:"align-middle", width:"25%"})              
        this.tableRemits.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-right", width:"10%"})              
        this.tableRemits.fields.push({key: 'price', label: 'Precio', class:"align-middle text-right", width:"10%"})              
        this.tableRemits.fields.push({key: 'total', label: 'Total', class:"align-middle text-right", width:"10%"})       
        this.tableRemits.fields.push({key: 'action', label: '', class:"align-middle text-center", width:"10%"})              
      },      
      onRowSelected(item) {         
        this.arr.purchase.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },
      setConfigTablePurchaseShow() {
        var arrTable = document.getElementsByClassName('table-purchase-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '200px'
        }        
      },
      setConfigTablePurchaseHide() {
        var arrTable = document.getElementsByClassName('table-purchase-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-purchase-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-purchase-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      forceRender() {
        this.componentKey += 1;  
      },

      // EXPORT
      prepareExport(data) {             
        this.arr.export = []
        data.forEach(element => {
          var suppliersID = 0
          var suppliersCode = ""
          var suppliersName = ""
          var currencyID = 0
          var currencyName = ""
          var typeVoucherID = 0
          var typeVoucherName = ""
          var pointSalesID = 0
          var pointSalesName = ""

          if(element.supplier) {
            suppliersID = element.supplier.id
            suppliersCode = element.supplier.code
            suppliersName = element.supplier.name            
          }

          if(element.currency) {
            currencyID = element.currency.id
            currencyName = element.currency.name            
          }          

          if(element.type_voucher) {
            typeVoucherID = element.type_voucher.id
            typeVoucherName = element.type_voucher.name                        
          }

          if(element.points_sales) {
            pointSalesID = element.points_sales.id
            pointSalesName = element.points_sales.name                        
          }          

          this.arr.export.push({
            id: element.id,
            date: element.date,      
            date_expiration: element.date_expiration,
            date_imputation: element.date_imputation,    
            typeVoucherID: typeVoucherID,
            typeVoucherName: typeVoucherName,
            letter: element.letter,
            point_sale: element.point_sale,
            number: element.number,            
            suppliersID: suppliersID,
            suppliersCode: suppliersCode,
            suppliersName: suppliersName,
            currencyID: currencyID,
            currencyName: currencyName,            
            amount_iva: element.amount_iva,
            amount_net: element.amount_net,
            amount_not_taxed: element.amount_not_taxed,
            amount_perceptions: element.amount_perceptions,
            amount_total: element.amount_total,
            observations: element.observations,
            pointSalesID: pointSalesID,
            pointSalesName: pointSalesName,            
            ivaConditionID: 0,
            ivaConditionName: '',
            detIva_net: 0,
            detIva_iva: 0,
            detIva_total: 0,
            perceptionsID: 0,
            perceptionsName: '',
            detPerc_total: 0,
          })

          element.net.forEach(element1 => {
            var ivaConditionID = 0            
            var ivaConditionName = ''            

            if(element1.iva_condition) {
              ivaConditionID = element1.iva_condition.id
              ivaConditionName = element1.iva_condition.name
            }

            this.arr.export.push({
              ivaConditionID: ivaConditionID,
              ivaConditionName: ivaConditionName,
              detIva_net: element1.amount_net,
              detIva_iva: element1.amoun_iva,
              detIva_total: element1.amount_total,
            })
          })

          element.perceptions.forEach(element2 => {
            var perceptionsID = 0            
            var perceptionsName = ''            

            if(element2.perceptions) {
              perceptionsID = element2.perceptions.id
              perceptionsName = element2.perceptions.name
            }

            this.arr.export.push({
              perceptionsID: perceptionsID,
              perceptionsName: perceptionsName,
              detPerc_total: element2.amount_total,
            })
          })
        });
      },

      // ABM MAIN
      add() {										
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')        
        this.crud.form.date_expiration = moment().add(10,'d').format('YYYY-MM-DD')
        this.crud.form.date_imputation = moment().format('YYYY-MM-DD')        
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()        
        this.crud.form.suppliers_id = 0
        this.crud.form.suppliers = null               
        this.crud.form.currency_id = 0
        this.crud.form.currency = null                    
        this.crud.form.types_vouchers_id = 0
        this.crud.form.types_vouchers = null                     
        this.crud.form.letter = ''
        this.crud.form.point_sale = 0
        this.crud.form.number = 0 
        this.crud.form.invoiceRelated = []            
        this.crud.form.points_sales_id = 0
        this.crud.form.points_sales = null  

        this.crud.form.ivaDetail = {
          net: 0,
          iva: 0,
          iva_condition_id: 0,
          iva_condition: null,
          detail: [],          
        }

        this.crud.form.percepcionesDetail = {
          percepcion: 0,
          percepciones_id: 0,
          percepciones: null,
          detail: [],
        }        
        
        this.crud.form.amount_total = 0
        
        this.modal.form.active = true
        this.modal.form.title = 'Comprobante de Proveedor'
      },
      edit(item) {		           
        this.crud.form.id = item.id         
        this.crud.form.date = moment(item.date).format('YYYY-MM-DD')        
        this.crud.form.date_expiration = moment(item.date_expiration).add(10,'d').format('YYYY-MM-DD')
        this.crud.form.date_imputation = moment(item.date_imputation).format('YYYY-MM-DD')        
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id      
        this.crud.form.suppliers_id = item.suppliers_id
        this.crud.form.suppliers = item.supplier
        this.crud.form.currency_id = item.currency_id
        this.crud.form.currency = item.currency         
        this.crud.form.point_sale = item.point_sale
        this.crud.form.number = item.number                 
        this.crud.form.points_sales_id = item.points_sales_id
        this.crud.form.points_sales = item.points_sales
        this.crud.form.amount_total = item.amount_total

        this.loadTypeVouchersBySuppliers(item.suppliers_id)        
        this.loadConceptsBySupplier(item.supplier)
        this.forceRender()          
        setTimeout(()=>{
          this.crud.form.types_vouchers_id = item.types_vouchers_id
          this.crud.form.types_vouchers = item.type_voucher
          this.crud.form.letter = item.letter  
        },1000)
                
        this.crud.form.ivaDetail = {
          net: 0,
          iva: 0,
          iva_condition_id: 0,
          iva_condition: null,
          detail: [],          
        }
        if(item.net) {
          item.net.forEach(element => {            
            this.crud.form.ivaDetail.detail.push({
              iva_condition_id: element.iva_conditions_id,
              iva_condition: element.iva_condition,
              net: element.amount_net,
              iva: element.amount_iva,
              total: element.total,
            })
          });          
        }        

        this.crud.form.percepcionesDetail = {
          percepcion: 0,
          percepciones_id: 0,
          percepciones: null,
          detail: [],
        }   
        if(item.perceptions) {
          item.perceptions.forEach(element => {
            this.crud.form.percepcionesDetail.detail.push({
              percepcion: element.amount_total,
              percepciones_id: element.perceptions_id,
              percepciones: element.perception,
            })                         
          });
        }

        this.crud.form.invoiceRelated = []
        if(item.purchase_related) {
          item.purchase_related.forEach(element => {            
            this.crud.form.invoiceRelated.push({
              "type_voucher": element.purchase_related.type_voucher,
              "point_sale": element.purchase_related.point_sale,
              "number": element.purchase_related.number,
              'amount': element.amount_total,
              "id": element.purchase_related.id,
            })    
          });
        }

        if(item.accounting_entries) {
          item.accounting_entries.details.forEach(element => {
            if(this.crud.form.conceptsDetails) {
              this.crud.form.conceptsDetails.forEach(concept => {
                if(element.accounting_accounts_id == concept.accounting_accounts_id) {
                  concept.amount = element.total                  
                }
              });
            }
          });
        }

        this.modal.form.active = true
        this.modal.form.title = 'Editar Comprobante de Proveedor'           
      },        
      remove(item) {
        this.crud.form.id = item.id
        
        var voucher = "ID #" + item.id
        if(item.type_voucher && item.point_sale) {
          voucher = item.type_voucher.name + ' ' + item.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
        }        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el comprobante ' + voucher + '?', {
          title: 'Borrar Comprobante',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarComprobante(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterPurchase()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },          
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del comprobante?', {
          title: 'Generar Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            var result = null
            if(this.crud.form.id) {
              result = serviceAPI.editarComprobante(this.crud.form);  
            } else {
              result = serviceAPI.agregarComprobante(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterPurchase()
              this.$awn.success("Comprobante generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },  
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el comprobante?', {
          title: 'Cancelar el Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },

      // CALC
      getPendingVoucherPurchase(item) {
        return parseFloat(item.amount_total) - parseFloat(item.amount_impute)
      },

      // ABM VALIDACIONES
      purchaseValidDetailGeneral() {        
        if( this.crud.form.suppliers_id && 
            this.crud.form.types_vouchers_id && 
            this.crud.form.letter && 
            this.crud.form.point_sale && 
            this.crud.form.number &&
            this.crud.form.currency_id &&
            this.crud.form.amount_total) {

          this.loadIvaCondition()
          this.loadPercepciones()

          if(this.crud.form.letter!='A') {            
            this.getContabilidad()            
          }

          return true;
        } else {
          if(!this.crud.form.suppliers_id) {
            this.$awn.alert("No se cargó el proveedor");
            return false;
          }            

          if( !this.crud.form.types_vouchers_id ||
              !this.crud.form.letter || 
              !this.crud.form.point_sale ||
              !this.crud.form.number ) {                
            this.$awn.alert("Faltan datos del comprobante");
            return false;         
          }
          
          if(!this.crud.form.currency_id) {
            this.$awn.alert("No se cargó la moneda");
            return false;
          }   

          if(!this.crud.form.amount_total) {
            this.$awn.alert("No se cargó el importe");
            return false;
          }               
        }        
      },
      purchaseValidItemDetail() {
        if(this.crud.form.letter=='A') {
          if(this.getDiffTotal() == 0) {            
            this.getContabilidad()
            return true;
          } else {
            this.$awn.alert("El saldo declarado no es igual al desglose");
            return false;
          }
        } else {          
          this.getContabilidad()
          return true;
        }
      },
      purchaseValidItemConcepts() {
        if(this.getDiffTotalConcepts() == 0) {     
          this.getContabilidad()                 
          return true;
        } else {
          this.$awn.alert("Se encontraron diferencia entre el saldo total y el desgloce por conceptos");
          return false;
        }
      },      
      purchaseValidCrudRelation() {
        var sum = 0
        if(this.crud.form.invoiceRelated.length) {
          this.crud.form.invoiceRelated.forEach(element => {
            sum = parseFloat(sum) + parseFloat(element.amount)
          });

          if(parseFloat(sum) != parseFloat(this.crud.form.amount_total)) {
            this.$awn.alert("Falta imputar comprobantes");
            return false;                      
          } else {
            return true          
          }
        } else {
          this.$awn.alert("Es obligatorio vincular una o mas factura a una nota de crédito o débito");
          return false;          
        }
      },                 
      purchaseValidItemAccounting() {
        if(this.crud.form.accountingEntry) {
          return true
        } else {
          this.$awn.alert("Revisar el Asiento Contable");
          return false
        }
      },

      // ABM FLUJO
      loadSuppliers (object) {   
        if(!this.crud.form.id) {
          if(object){
            if(this.crud.form.suppliers_id != object.id) {
              this.forceRender()
            }

            this.crud.form.suppliers = object
            this.crud.form.suppliers_id = object.id   
                      
            if(object.data_fiscal) {
              if(object.data_fiscal.points_sales_id) {
                if(!this.crud.form.points_sales_id) {
                  this.crud.form.points_sales = object.data_fiscal.points_sales
                  this.crud.form.points_sales_id = object.data_fiscal.points_sales_id                
                }
              }          
              if(object.data_fiscal.methods_payment_id) {              
                let days = parseInt(object.data_fiscal.methods_payment.term)
                this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')
              }            
            }
            
            this.loadTypeVouchersBySuppliers(object.id)
            this.loadConceptsBySupplier(object)          
          } else {
            this.crud.form.suppliers = null
            this.crud.form.suppliers_id = 0        
            
            this.crud.form.points_sales = null
            this.crud.form.points_sales_id = 0

            this.cleanDataVoucher()          
          }
        } 
      },   
      loadPointsSales (object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id             
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0
        }
      },         
      loadCurrency (object) {        
        if(object){
          this.crud.form.currency = object
          this.crud.form.currency_id = object.id                      
        } else {
          this.crud.form.currency = null
          this.crud.form.currency_id = 0   
        }
      },         
      loadTypeVouchersBySuppliers(suppliers_id) {
        let loader = this.$loading.show();
        
        this.cleanDataVoucher()
        var result = serviceAPI.obtenerVoucherBySupplier({suppliers_id: suppliers_id});

        result.then((response) => {                          
          loader.hide()
          var data = response.data
          this.arr.typeVouchers = data

          data.forEach(element => {
            this.arr.select.typeVouchers.push({
              value: element.id,
              text: element.name
            })
          });

          this.crud.form.types_vouchers_id = 0
          this.crud.form.letter = ''
        })
        .catch(error => {
          loader.hide()          
          this.$awn.alert(Error.showError(error));
        })
      },
      setLetter(event) {  
        if(!this.crud.form.id) {
          var item = null
          this.arr.typeVouchers.forEach(element => {
            if(element.id == event) {
              item = element
            }
          });      
          this.crud.form.letter = item.letter
          this.crud.form.types_vouchers_id = item.id
          this.crud.form.types_vouchers = item 
          
          this.crud.form.ivaDetail = {
            net: 0,
            iva: 0,
            iva_condition_id: 0,
            iva_condition: null,
            detail: [],        
          }

          this.crud.form.percepcionesDetail = {
            percepcion: 0,
            percepciones_id: 0,
            percepciones: null,
            detail: [],
          }   
          
          this.loadConceptsBySupplier(this.crud.form.suppliers)
          this.forceRender()          
        }
      },
      cleanDataVoucher() {
        this.arr.typeVouchers = null
        this.arr.select.typeVouchers = []

        this.crud.form.letter = ''
        this.crud.form.types_vouchers_id = 0
        this.crud.form.types_vouchers = null 

        this.crud.form.currency_id = 1
        this.crud.form.currency = null 

        this.crud.form.ivaDetail = {}
      },

      // LOAD IVA
      loadIvaCondition() {        
        var result = serviceAPI.obtenerIvaCondition();

        result.then((response) => {                          
          var data = response.data
          this.arr.ivaCondition = data

          this.arr.select.ivaCondition = []
          data.forEach(element => {
            this.arr.select.ivaCondition.push({
              value: element.id,
              text: element.name
            })
          });

          this.crud.form.ivaDetail.iva_condition_id = 5
          this.crud.form.ivaDetail.iva_condition = this.arr.ivaCondition[4]
        })    
      },
      changeIvaCondition(event) {
        var item = null
        this.arr.ivaCondition.forEach(element => {
          if(element.id == event) {
            item = element
          }
        }); 

        this.crud.form.ivaDetail.iva_condition_id = item.id
        this.crud.form.ivaDetail.iva_condition = item

        this.crud.form.ivaDetail.iva =  ( parseFloat(this.crud.form.ivaDetail.net) * parseFloat(item.aliquot) ) / 100 
        this.crud.form.ivaDetail.iva = parseFloat(this.crud.form.ivaDetail.iva.toFixed(2))
      },      
      changeNet() {
        var item = null
        this.arr.ivaCondition.forEach(element => {
          if(element.id == this.crud.form.ivaDetail.iva_condition_id) {
            item = element
          }
        }); 

        this.crud.form.ivaDetail.iva_condition_id = item.id
        this.crud.form.ivaDetail.iva_condition = item

        this.crud.form.ivaDetail.iva =  ( parseFloat(this.crud.form.ivaDetail.net) * parseFloat(item.aliquot) ) / 100 
        this.crud.form.ivaDetail.iva = parseFloat(this.crud.form.ivaDetail.iva.toFixed(2))
      },           
      addItemIVA()  {
        if(!this.crud.form.ivaDetail.iva_condition_id) {
          this.$awn.alert("No se cargó la condición de IVA");
          return false;          
        }

        if(!parseFloat(this.crud.form.ivaDetail.net)) {
          this.$awn.alert("No se cargó el importe neto");
          return false;          
        }        

        if( !parseFloat(this.crud.form.ivaDetail.iva) && 
            parseFloat(this.crud.form.ivaDetail.iva_condition.aliquot)>0) {
          this.$awn.alert("No se cargó el importe de IVA");
          return false;          
        }     
        
        this.crud.form.ivaDetail.detail.push({
          net: this.crud.form.ivaDetail.net,
          iva: this.crud.form.ivaDetail.iva,
          iva_condition: this.crud.form.ivaDetail.iva_condition,
          iva_condition_id: this.crud.form.ivaDetail.iva_condition_id,
        })        

        this.crud.form.ivaDetail.net = 0
        this.crud.form.ivaDetail.iva = 0
        this.crud.form.ivaDetail.iva_condition_id = 5
        this.crud.form.ivaDetail.iva_condition = this.arr.ivaCondition[4]
      },
      removeItemIVA(index) {
        this.crud.form.ivaDetail.detail.splice(index, 1)
      },          

      // LOAD PERCEPCIONES
      loadPercepciones() {
        var result = serviceAPI.obtenerPercepciones();

        result.then((response) => {                                  
          var data = response.data
          this.arr.percepciones = data

          this.arr.select.percepciones = []
          data.forEach(element => {
            this.arr.select.percepciones.push({
              value: element.id,
              text: element.name
            })
          });
        })        
      },
      changePercepciones(event) {
        var item = null
        this.arr.percepciones.forEach(element => {
          if(element.id == event) {
            item = element
          }
        }); 

        this.crud.form.percepcionesDetail.percepciones_id = item.id
        this.crud.form.percepcionesDetail.percepciones = item
      },      
      addItemPercepcion() {
        if(!this.crud.form.percepcionesDetail.percepciones_id) {
          this.$awn.alert("No se cargó la Percepción");
          return false;          
        }

        if(!parseFloat(this.crud.form.percepcionesDetail.percepcion)) {
          this.$awn.alert("No se cargó el importe de la Percepción");
          return false;          
        }        
        
        var sum = parseFloat(this.crud.form.percepcionesDetail.percepcion)
        this.crud.form.percepcionesDetail.detail.forEach(element => {
          sum = parseFloat(sum) + parseFloat(element.percepcion)
        })
        if(sum>this.crud.form.amount_total) {
          this.$awn.alert("Las percepciones no pueden ser mayor al total de la factura");
          return false;          
        }        

        this.crud.form.percepcionesDetail.detail.push({
          percepcion: this.crud.form.percepcionesDetail.percepcion,
          percepciones_id: this.crud.form.percepcionesDetail.percepciones_id,
          percepciones: this.crud.form.percepcionesDetail.percepciones,
        })        

        this.crud.form.percepcionesDetail.percepcion = 0
        this.crud.form.percepcionesDetail.percepciones_id = 0
        this.crud.form.percepcionesDetail.percepciones = null
      },
      removeItemPercepcion(index) {
        this.crud.form.percepcionesDetail.detail.splice(index, 1)
      },      
    
      // GET TOTALES
      getSubtotal() {
        var sum = 0
        if(this.crud.form.ivaDetail.detail) {
          this.crud.form.ivaDetail.detail.forEach(element => {
            sum = sum + parseFloat(element.net)
          });
        }
        return parseFloat(sum.toFixed(2))
      },
      getIvaTotal() {
        var sum = 0
        if(this.crud.form.ivaDetail.detail) {
          this.crud.form.ivaDetail.detail.forEach(element => {
            sum = sum + parseFloat(element.iva)
          });
        }
        return parseFloat(sum.toFixed(2))
      },
      getPercepcionesTotal() {
        var sum = 0

        if(this.crud.form.percepcionesDetail.detail) {
          this.crud.form.percepcionesDetail.detail.forEach(element => {
            sum = sum + parseFloat(element.percepcion)
          });
        }

        return parseFloat(sum.toFixed(2))
      },
      getTotal() {
        return this.getSubtotal() + this.getIvaTotal() + this.getPercepcionesTotal()
      },
      getDiffTotal() {        
        return this.getTotal() - parseFloat(this.crud.form.amount_total)
      },

      // INVOICE RELACIONADA EN NC
      getInvoiceRelated(event) {
        this.crud.form.invoiceRelated = event
      },

      // FILTER
      filterLoad() {
        this.filterLoadSuppliers()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {            
            this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
          });          
        })   
      },      
      filterPurchase(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarComprobante(this.filters, this.table.currentPage)        
        result.then((response) => {      
          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.purchase = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }
            
            if((element.amount_total != element.amount_impute) && element.type_voucher.type_balance=='+') {
              element._rowVariant = 'warning'
            }                    
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })                         
        .catch(error => {              
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },    

      // COMPROBANTE DETAIL
      openSub(item) {                
        this.itemSelected = item        
        
        this.tableSubIva.items = item.net      
        this.tableSubPercepcion.items = item.perceptions
        this.tableSubRemits.items = Funciones.groupJSON(item.purchase_remits_detail, 'remit','id')        
        this.tableRemits.items = this.itemSelected.purchase_remits_detail
        
        this.loadStyleConfig()
        this.loadImputations(item)
      },    
      hideSub() {
        this.tableSubIva.items=[]
        this.tableSubPercepcion.items=[]
        this.itemSelected = null
      },     
      openDetail() {              
        this.tableSubIva.items = this.itemSelected.net      
        this.tableSubPercepcion.items = this.itemSelected.perceptions    
        this.tableRemits.items = this.itemSelected.purchase_remits_detail
       
        if(this.itemSelected.accounting_entries){
          if(this.itemSelected.accounting_entries.details){
            this.tableAccounting.items = this.itemSelected.accounting_entries.details
          } else {
            this.tableAccounting.items = []
          }
        } else {
          this.tableAccounting.items = []
        }

        this.modal.detail.title = "Detalle del Comprobante"
        this.modal.detail.active = true
      },       

      // CONCEPTOS
      loadConceptsBySupplier(object) {
        var data = []
        if(object.accounting_account) {
          var concepts = object.accounting_account.concepts  
          if(concepts) {
            concepts.forEach(element => {
              data.push({
                alias: element.alias,
                accounting_accounts_id: element.accounting_accounts_id,
                accounting_accounts: element.accounting_accounts,
                amount: 0,
              })
            });          
          }                      
        }
        this.crud.form.conceptsDetails = data
      },
      getTotalConcepts() {
        var sum = 0
        this.crud.form.conceptsDetails.forEach(element => {
          sum = parseFloat(sum) + parseFloat(element.amount)
        });   
        return sum     
      },
      getDiffTotalConcepts() {        
        if(this.crud.form.letter == 'A') {
          return this.getTotalConcepts() - this.getSubtotal()
        } else {
          return this.getTotalConcepts() - parseFloat(this.crud.form.amount_total)
        }
      },

      // CARGAR ARCHIVO
      openSetFile(item){
        this.itemSelected = item

        this.crud.formFile.id = item.id
        this.crud.formFile.file = null        
        this.crud.formFile.fileLink = item.file

        this.modal.formFile.active = true
        this.modal.formFile.title = 'Cargar Documento Físico'
      },      
      saveFile() {
        if(!this.crud.formFile.id) {
          this.$awn.alert("No existe el comprobante de compra");
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Desea guardar un documento físico de respaldo para este comprobante?', {
          title: 'Documento Físico',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {  
            let loader = this.$loading.show();

            var file = new FormData();
            if(this.crud.formFile.fileLink) {
              file.append("file", this.crud.formFile.fileLink);
            } else {        
              if(this.crud.formFile.file) {          
                file.append("file", this.crud.formFile.file);
              }
            }       

            var result = serviceAPI.agregarAdjuntoCompra(JSON.stringify(this.crud.formFile), file);

            result.then((response) => {
              this.modal.formFile.active = false
              loader.hide()
              this.filterPurchase()
              this.$awn.success("Documento guardado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })         
          }
        })   
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      }, 

      // RELACIONAR REMITOS
      openSetRemits(item){
        this.itemSelected = item
        this.itemSelectedRemit = []
        
        var result = serviceAPI.obtenerRemitosSinRelacionar({
          suppliers_id: item.suppliers_id,
          points_sales_id: item.points_sales_id,
        });

        result.then((response) => {                                  
          var data = response.data          
          this.itemSelectedRemit = data                    
        })        

        this.modal.formRemits.active = true
        this.modal.formRemits.title = 'Relacionar Remito'
      },    
      saveRemits() {
        if(this.itemSelectedRemit && this.itemSelectedRemit.length) {
          var valid = false
          this.itemSelectedRemit.forEach(element => {                        
            if(element.check!=undefined && element.check == true) {
              valid = true
            }
          });
          if(!valid) {
            this.$awn.alert("No se agregaron items para relacionar");          
            return false
          }         
          
          var total = this.getSummaryRemits()
          if(this.itemSelected.purchase_remits_detail) {
            this.itemSelected.purchase_remits_detail.forEach(element => {              
              total = parseFloat(total) + (parseFloat(element.price_cost) * parseFloat(element.quantity))
            });
          }          
          if(parseFloat(this.itemSelected.amount_net) < parseFloat(total)) {
            this.$awn.alert("El monto de los items de remitos seleccionados no pueden ser mayor al subtotal del comprobante");          
            return false
          }
        }

        this.$bvModal.msgBoxConfirm('¿Desea continuar con la relación de los items con el comprobante?', {
          title: 'Relación de Remitos',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'RELACIONAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {   
            let loader = this.$loading.show();

            var result = serviceAPI.guardarComprobanteRelacionadosRemitos({
              id: this.itemSelected.id,
              details: this.itemSelectedRemit,        
            });

            result.then((response) => {
              this.modal.formRemits.active = false
              loader.hide()
              this.filterPurchase()
              this.$awn.success("Items de remitos vinculado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },    
      unlinkRemitsDetail(item) {
        this.$bvModal.msgBoxConfirm('¿Desea desvincular el item de remito del comprobante?', {
          title: 'Desvincular Item de Remito',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'DESVINCULAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {   
            let loader = this.$loading.show();

            var result = serviceAPI.desvincularDetalleRemito({
              id: item.id,              
            });

            result.then((response) => {              
              loader.hide()
              this.filterPurchase(true)
              this.$awn.success("Items de remitos desvinculado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      getProductCode(item) {
        var code = ''
        var prod = null

        if(item.products_code) {
          code = item.products_code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }
      },      
      getProductName(item) {        
        var name = ''
        var prod = null

        if(item.products_name) {
          name = item.products_name
        }          
        
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }
      },               
      getSummaryRemits() {        
        var total = 0        
        if(this.itemSelectedRemit) {                    
          this.itemSelectedRemit.forEach(element => {                        
            if(element.check != undefined && element.check) {
              total = total + (parseFloat(element.price_cost) * parseFloat(element.quantity))
            }            
          });
        }
        return total.toFixed(2)        
      },

      // IMPUTATIONS
      loadImputations(item) {             
        var arrImputations = []        
        if(item.payments) {
          item.payments.forEach(element => {            
            arrImputations.push({              
              date: element.payment.date,
              reference: element.payment.type_voucher.name + ' ' + element.payment.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.payment.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }

        if(item.purchase_related) {          
          item.purchase_related.forEach(element => {              
            arrImputations.push({
              date: element.purchase_related.date,
              reference: element.purchase_related.type_voucher.name + ' ' + element.purchase_related.point_sale.toString().padStart(4,'0') + '-' + element.purchase_related.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }   
        
        if(item.purchase_related_n_c) {          
          item.purchase_related_n_c.forEach(element => {              
            arrImputations.push({
              date: element.purchase.date,
              reference: element.purchase.type_voucher.name + ' ' + element.purchase.point_sale.toString().padStart(4,'0') + '-' + element.purchase.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }   
  
        this.tableImputation.items = arrImputations
      },      

      /****************
       * CONTABILIDAD *
       * **************/  
      getContabilidad() {          
        this.contabilidad.render = false
        this.contabilidad.typeDebe = ""
        this.contabilidad.typeHaber = "",
        this.contabilidad.defaultDebe = []
        this.contabilidad.defaultHaber = []
        this.contabilidad.reference = this.getRefVoucher
        this.contabilidad.amountTotal = parseFloat(this.crud.form.amount_total)

        if( this.crud.form.types_vouchers.reference == 'factura' || 
            this.crud.form.types_vouchers.reference == 'notadebito' || 
            this.crud.form.types_vouchers.reference == 'gasto-nd') {
          this.contabilidad.defaultDebe = this.getContabilidadAsientoCompra('debe')
          this.contabilidad.defaultHaber = this.getContabilidadAsientoCompra('haber')
        } 
        if( this.crud.form.types_vouchers.reference == 'notacredito' ||
            this.crud.form.types_vouchers.reference == 'credito-nd') {
          this.contabilidad.defaultDebe = this.getContabilidadAsientoCredito('debe')
          this.contabilidad.defaultHaber = this.getContabilidadAsientoCredito('haber')          
        }      

        this.contabilidad.render = true
        this.forceUpdate()
      },

      getAsiento(object) {
        this.crud.form.accountingEntry = object
      },
      forceUpdate() {
        this.itemAccountingForceUpdate = this.itemAccountingForceUpdate + 1
      },    
      
      getContabilidadAsientoCompra(type) {              
        if(type == 'debe') {    
          var arrDebe = []    

          if(this.crud.form.conceptsDetails.length) {
            this.crud.form.conceptsDetails.forEach(element => {                   
              if(parseFloat(element.amount)) {
                arrDebe.push({
                  account: element.accounting_accounts,
                  amount: parseFloat(element.amount),
                  disabled: true,
                })            
              }  
            });
          } else {
            arrDebe.push({
              account: this.crud.form.suppliers.accounting_account_secondary,
              amount: parseFloat(this.crud.form.amount_total) - parseFloat(this.getIvaTotal()) - parseFloat(this.getPercepcionesTotal()),
              disabled: true,
            })
          }

          if(this.crud.form.ivaDetail.detail) {
            this.crud.form.ivaDetail.detail.forEach(element => {              
              arrDebe.push({
                account: element.iva_condition.accounting_account_secondary,
                amount: parseFloat(element.iva),
                disabled: true,                  
              })
            });
          }

          if(this.crud.form.percepcionesDetail.detail) {
            this.crud.form.percepcionesDetail.detail.forEach(element => {              
              arrDebe.push({
                account: element.percepciones.accounting_account_secondary,
                amount: parseFloat(element.percepcion),
                disabled: true,                  
              })
            });
          }          
          return arrDebe
        }        

        if(type == 'haber') {        
          var arrHaber = [
            {
              account: this.crud.form.suppliers.accounting_account_default,
              amount: parseFloat(this.crud.form.amount_total),
              disabled: true,
            }
          ]

          return arrHaber
        }        
      },    
      getContabilidadAsientoCredito(type) {              
        if(type == 'debe') {        
          var arrDebe = [
            {
              account: this.crud.form.suppliers.accounting_account_default,
              amount: parseFloat(this.crud.form.amount_total),
              disabled: true,
            }
          ] 

          return arrDebe
        }        

        if(type == 'haber') {     
          var arrHaber = []

          if(this.crud.form.conceptsDetails.length) {
            this.crud.form.conceptsDetails.forEach(element => {         
              if(parseFloat(element.amount)) {          
                arrHaber.push({
                  account: element.accounting_accounts,
                  amount: parseFloat(element.amount),
                  disabled: true,
                })             
              } 
            });
          } else {          
            arrHaber.push({
              account: this.crud.form.suppliers.accounting_account_secondary,
              amount: parseFloat(this.crud.form.amount_total) - parseFloat(this.getIvaTotal()) - parseFloat(this.getPercepcionesTotal()),
              disabled: true,
            })            
          }

          if(this.crud.form.ivaDetail.detail) {
            this.crud.form.ivaDetail.detail.forEach(element => {              
              arrHaber.push({
                account: element.iva_condition.accounting_account_secondary,
                amount: parseFloat(element.iva),
                disabled: true,                  
              })
            });
          }

          if(this.crud.form.percepcionesDetail.detail) {
            this.crud.form.percepcionesDetail.detail.forEach(element => {              
              arrHaber.push({
                account: element.percepciones.accounting_account_secondary,
                amount: parseFloat(element.percepcion),
                disabled: true,                  
              })
            });
          }      

          return arrHaber
        }        
      },    
    }
  }
</script>
<style>
  .crud-purchase-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-purchase-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-purchase-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-purchase-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }  
  .crud-purchase-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-purchase-item-add {
    margin-top: 30px;
  }
  .crud-purchase-impuestos-divisor {
    border-right-width: 1px;
    border-right-color: #e4e7ea;
    border-right-style: solid;    
    margin-bottom: 10px;
  }
  .crud-purchase-totales-add {
    font-weight: bold;
    font-size: 15px;
  }  
  .crud-purchase-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }  
  .crud-purchase-value-table-custom {
    padding-left: 5px;
  }    
  .table-full-detail-purchase {
    overflow: auto;
    max-height: 350px;    
  }      
</style>